import React, { Component } from "react";
import { connect } from "react-redux";

import { TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { toggle3DotMenuAction } from "../../../actions/settingsAction";

import Profilecomponent from "./profilecomponent";
import { Stack, Alert, Box, CircularProgress, Typography, Button } from "@mui/joy";
import Warning from '@mui/icons-material/Warning';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      isCardRemove: false,
      isFullScreen: false,
      isCollapsed: false,
    };
  }
  render() {
   
    const { activeTab } = this.state;
    const { user, data, campus} = this.props;

    return (
      <>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={1} className={classnames(["fade show"])}>
            <div className="row clearfix row-deck">
              <div className="col-xl-4 col-lg-4 col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Datos del Alumno</h3>
                    {/* <div className="card-options">
                      <a
                        href
                        className="card-options-remove"
                        data-toggle="card-remove"
                      >
                        <i className="fe fe-x"></i>
                      </a>
                      <div className="item-action dropdown ml-2">
                        <a href data-toggle="dropdown">
                          <i className="fe fe-more-vertical"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right">
                          <a href className="dropdown-item">
                            <i className="dropdown-icon fa fa-eye"></i> View
                            Details{" "}
                          </a>
                          <a href className="dropdown-item">
                            <i className="dropdown-icon fa fa-share-alt"></i>{" "}
                            Share{" "}
                          </a>
                          <a href className="dropdown-item">
                            <i className="dropdown-icon fa fa-cloud-download"></i>
                            Download
                          </a>
                          <div className="dropdown-divider"></div>
                          <a href className="dropdown-item">
                            <i className="dropdown-icon fa fa-copy"></i> Copy to
                          </a>
                          <a href className="dropdown-item">
                            <i className="dropdown-icon fa fa-folder"></i> Move
                            to
                          </a>
                          <a href className="dropdown-item">
                            <i className="dropdown-icon fa fa-edit"></i> Rename
                          </a>
                          <a href className="dropdown-item">
                            <i className="dropdown-icon fa fa-trash"></i> Delete
                          </a>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="card-body">
                    <Profilecomponent user={user} campus={campus} section={data.section} />
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Datos Academicos</h3>
                    <div className="card-options">
                      <div className="item-action dropdown ml-2">
                      {this.props.status !== 2 && 
                        <button onClick={() => this.props.download()} className="center btn btn-primary">
                          <i className="fe fe-file"></i> Descargar Libreta
                        </button>
                      }
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {this.props.status !== 2 ? (
                      <table className="table table-striped mt-4">
                        <thead>
                          <tr>
                            <th rowSpan={2}>Asignatura</th>
                            <th className="w100 text-center" colSpan={2}>Bimestre 1</th>
                            <th className="w100 text-center" colSpan={2}>Bimestre 2</th>
                            <th className="w100 text-center" colSpan={2}>Bimestre 3</th>
                            <th className="w100 text-center" colSpan={2}>Bimestre 4</th>
                            <th className="w100 text-center" colSpan={2} style={{backgroundColor: '#5d2837', color:'white'}}>Promedio Final</th>
                          </tr>
                          <tr>
                            {/* <th>Asignatura</th> */}
                            {/* <th className="w100 text-right">Cuant.</th>
                            <th className="w100">Cual.</th>
                            <th className="w100 text-right">Cuant.</th>
                            <th className="w100">Cual.</th>
                            <th className="w100 text-right">Cuant.</th>
                            <th className="w100">Cual.</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {data.courses?.map((course) => (
                            <tr key={course.id} style={String(course.id).includes("a") ? {background: '#5d2837', color: 'white'} : {}} >
                              <td>{course.name}</td>
                              <td>{course.scores.pb1.score}</td>
                              <td>{course.scores.pb1.letterGrade}</td>
                              <td>{course.scores.pb2.score}</td>
                              <td>{course.scores.pb2.letterGrade}</td>
                              <td>{course.scores.pb3.score}</td>
                              <td>{course.scores.pb3.letterGrade}</td>
                              <td>{course.scores.pb4.score}</td>
                              <td>{course.scores.pb4.letterGrade}</td>
                              <td style={{backgroundColor: '#5d2837', color:'white'}}>{course.scores.pb4.score}</td>
                              <td style={{backgroundColor: '#5d2837', color: 'white'}}>{course.scores.pb4.letterGrade}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <Stack spacing={2}>
                        <Alert
                          variant="soft"
                          color="danger"
                          invertedColors
                          startDecorator={
                            <CircularProgress size="lg" color="danger">
                              <Warning />
                            </CircularProgress>
                          }
                          sx={{ alignItems: 'flex-start', gap: '1rem' }}
                        >
                          <Box sx={{ flex: 1 }}>
                            <Typography level="title-md">Se perdió la conección</Typography>
                            <Typography level="body-md">
                              Tenemos un problema con el servidor. Si el problema persiste comunícate con la institución.
                            </Typography>
                            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                              {/* <Button variant="outlined" size="sm">
                                Open network settings
                              </Button>
                              <Button variant="solid" size="sm">
                                Try again
                              </Button> */}
                            </Box>
                          </Box>
                        </Alert>
                      </Stack>
                    )}
                  </div>
                  <div className="card-footer">
                    <small>
                      Colegios Monserrat
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </TabContent>
      </>
    );
  }
}

var mystyle = {
  
};

const mapStateToProps = (state) => ({
  is3DotMenu: state.settings.is3DotMenu,
});

const mapDispatchToProps = (dispatch) => ({
  toggle3DotMenuAction: (e) => dispatch(toggle3DotMenuAction(e)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
