import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { TabContent, TabPane, Nav, NavLink, NavItem } from "reactstrap";
import classnames from "classnames";
import CampusService from '../services/CampusService';
import PaymentService from "../services/PaymentService";
import AcademyService from "../services/AcademyService";
import Campus from '../models/Campus';
import { connect } from "react-redux";
import { useUI, usePageUI } from '../app/context/ui';
import ManagementReport from '../components/university/Dashboard/Dashboard'
import Swal from "sweetalert2";
// import AcademyService from "../services/AcademyService";

const DashboardStudent = ({user, campus}) => {
  const {alertUI} = usePageUI();
  const {blockUI} = useUI();
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [enroll, setEnroll] = useState('');
  const [campuses, setCampuses] = useState([]);
  const [limit, setLimit] = useState(0)
  const [campusData, setCampusData] = useState({});
  const [status, setStatus] = useState(2);
  const [data, setData] = useState([]);
  const academyService = new AcademyService();

  useEffect(() => {
    CampusService.init(user.accessToken);
    PaymentService.init(user.accessToken);
    setCampusData(Campus);
  }, [user]);

  useEffect(() => {
    (async function getRecords() {
      await getCampuses(limit);
      await getStatus();
    })();
  }, [limit]);

  const getCampuses = async (l) => { 
    setLoading(true);
    try {
      const response = await CampusService.list();
      setCampuses( response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getStatus = async (l) => {
    blockUI.current.open(true, 'Estamos generando...');
    setLoading(true);
    try {
      const response = await PaymentService.delayed();
      setStatus(response.data.status);
      setEnroll(response.data.enrollId);
      if (response.data.status !== 2) {
        const res = await academyService.reportStudent(response.data.enrollId, 4, 'json');
        setData(res.data);
        blockUI.current.open(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      blockUI.current.open(false);
    }
  };

  const download = async () => {
    setLoading(true);
    try {
      console.log(data);
      const response = await academyService.reportStudent(enroll, 4, 'pdf');

      let urlDoc = response.data.resourceUrl;
      const fileName = urlDoc.split('/').pop();

      let file = document.createElement('a');
      file.href = urlDoc;
      file.setAttribute('download', fileName)
      file.setAttribute('target', '_blank')
      file.click();
  
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Alumnos</h1>
              <ol className="breadcrumb page-breadcrumb">
                <li className="breadcrumb-item">
                  {user.school ? user.school : 'YAKHANA'}
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Perfil
                </li>
              </ol>
            </div>
            <Nav tabs className="page-header-tab">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 1 })}
                  onClick={() => setActiveTab(1)}
                >
                  Perfil
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
      </div>
      <div className="section-body mt-4">
        <div className="container-fluid">
          <TabContent activeTab={activeTab}>
            <TabPane tabId={1} className={classnames(["fade show"])}>
              <ManagementReport status={status} data={data} download={download} user={user} campus={campus} />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    campus: state.campus
  };
};

export default connect(mapStateToProps)(DashboardStudent);
